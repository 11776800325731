.p {
  width: 30%;
  height: 25vh;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.p-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 3px;
  background-color: white;
}

.p-circle:nth-child(1) {
  background-color: #fa625c;
}

.p-circle:nth-child(2) {
  background-color: #fbb43a;
}

.p-circle:nth-child(3) {
  background-color: #3ec544;
}

.p-img {
  width: 100%;
  transition: all 0.5s ease-in-out;
  height: 100%;
  object-fit: cover;
  filter: grayscale(0.7);
}

.p:hover .p-img {
  transform: scale(108%);
  filter: grayscale(0);
}

@media screen and (max-width: 576px) {
  .p {
    width: 90%;
    margin: 20px auto;
  }
}

@media screen and (min-width: 577px) and (max-width: 800px) {
  .p {
    width: 45%;
    margin: 20px auto;
  }
}
