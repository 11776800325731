.c {
  position: relative;
}

.c-bg {
  width: 20px;
  height: 100%;
  background-color: #042940;
  position: absolute;
}

.c-wrapper {
  padding: 50px;
  display: flex;
}

.c-left {
  flex: 1;
  position: relative;
  margin: auto 0;
}

.c-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-title {
  font-size: 60px;
  width: 80%;
}

.c-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0;
  font-weight: 300;
}

.c-icon {
  font-weight: 900;
  font-size: 35px;
  margin-right: 20px;
}

.c-desc {
  font-weight: 300;
}

form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

input {
  width: 50%;
  height: 50px;
  border: none;
  border: 2px solid #042940;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
  outline: none;
  font-weight: bold;
}

textarea {
  width: 50%;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 5px;
  outline: none;
  font-weight: bold;
  border: 2px solid #042940;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

button {
  border: none;
  padding: 15px;
  background-color: #042940;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .c-wrapper {
    flex-direction: column;
    padding: 0 50px;
  }

  .c-title {
    font-size: 30px;
  }

  .c-info-item {
    margin: 20px 0;
    width: 100%;
  }

  .c-desc {
    display: none;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  input {
    width: 100%;
    height: 40px;
  }

  textarea {
    width: 100%;
  }

  button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 786px) {
  input {
    width: 100%;
    height: 40px;
  }

  textarea {
    width: 100%;
  }

  .c-title {
    font-size: 40px;
  }

  .c-icon {
    font-size: 30px;
  }

  .c-info-item {
    font-size: 14px;
  }
}

@media screen and (min-width: 787px) {
  form {
    width: 357px;
  }

  input {
    width: 100%;
  }

  textarea {
    width: 100%;
  }
}
